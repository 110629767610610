import axios from "axios";

export async function validateRecaptcha(data) {
  let properties = {
    headers: {
      Accept: "*/*",
      "Content-Type": "application/json",
    },
  };
  return await axios.post(
    // "https://33pmnf9gej.execute-api.eu-west-1.amazonaws.com/dev/evaluate-captcha",
    // "https://87baacqso5.execute-api.eu-west-1.amazonaws.com/dev/dev-rai-evaluate-captcha",
    process.env.REACT_APP_CAPTCHA_URL,
    JSON.stringify({
      response: data.response,
    }),
    properties
  );
}
