import React, { memo, useState } from "react";
import style from "./Form.module.scss";
import Dropdown from "../../../components/Dropdown/Dropdown.jsx";
import GoogleMapsPlaceSearch from "../../../components/GoogleMaps/GoogleMapsSearch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import Recaptcha from "../../Recaptcha/Recaptcha";
import { useData } from "../../../DataProvider";
import { Card, CardContent } from "@mui/material";
import HeightChangeDetector from "../../../components/HeightChangeDetector/HeightChangeDetector";
import ErrorMsg from "../../../components/ErrorMsg/ErrorMsg";
import categories from "../../../utils/categories.jsx";

const Form = (formProps) => {
const {
  specialCharacterFirstName,
  specialCharacterLastName,
  specialCharacterEmail,
  place,
  onBlurPlace,
  cRadioDisabled,
  setCRadioDisabled,
  onChangePlace,
  onChangeInputPlace,
  country,
  onChangeCountry,
  county,
  onChangeCounty,
  city,
  emptyCity,
  onChangeCity,
  firstName,
  lastName,
  email,
  onChangeFirstName,
  onChangeLastName,
  onChangeEmail,
  chosenCategory,
  onChangeOverEighteen,
  onChangeSubscription,
  submitBtn,
  errMsg,
} = formProps;
  const { counties, towns } = useData();
  const [notARobot, setNotARobot] = useState(false);
  const getResponse = (isNotRobot) => {
    setNotARobot(isNotRobot);
  };

  const buildTownList = (irishCounty) => {
    let town = [];
    towns.irishtowns.forEach((value, index) => {
      if (value.county === irishCounty) {
        town.push(value.town);
      }
    });
    return town;
  };

  const chosenCat = categories.find((x) => x.value === chosenCategory);

  return (
    <div className={style.container}>
      <HeightChangeDetector />
      <div className={style.title}>So, where would you like to nominate?</div>
      <div className={style.labelLef}>
        <div className={style.label}>
          Name of Hospitality Business <sup className={style.required}>*</sup>
        </div>
        <div className={style.placeSearch}>
          <GoogleMapsPlaceSearch
            placeholder="Start typing to search"
            value={place}
            onChange={onChangePlace}
            onChangeInput={onChangeInputPlace}
            countryChosen={country}
            onBlurInput={onBlurPlace}
          />
          <ErrorMsg
            show={errMsg['place']}
            msg="Name of Hospitality Business"
          />
        </div>
      </div>
      <div className={style.ckeckboxes} id="inp-country">
        <div className={`${cRadioDisabled ? style.disabledCheckboxes : style.checkboxContainer}`}>
          <p>Searching in</p>
          <label className={style.containerRadio} htmlFor="IE">
            Republic of Ireland
            <input
              type="radio"
              name="country"
              id="IE"
              value="IE"
              onChange={(e) => onChangeCountry(e.target.value)}
              checked={country === "IE"}
              disabled={cRadioDisabled}
            />
            <span className={style.checkmark}></span>
          </label>
          <label className={style.containerRadio} htmlFor="Northern Ireland">
            Northern Ireland
            <input
              type="radio"
              name="country"
              id="Northern Ireland"
              value="GB"
              onChange={(e) => onChangeCountry(e.target.value)}
              checked={country === "GB"}
              disabled={cRadioDisabled}
            />
            <span className={style.checkmark}></span>
          </label>
        </div>
        <label
          className={style.containerRadio}
          htmlFor="My establishment is not listed"
        >
          My establishment is not listed
          <input
            type="checkbox"
            name="country"
            id="My establishment is not listed"
            value="not_listed"
            onChange={(e) => { !e.target.checked ? onChangeCountry("IE") : onChangeCountry(e.target.value); setCRadioDisabled(e.target.checked); }}
            checked={country === "not_listed"}
          />
          <span className={style.checkmark}></span>
        </label>

        <ErrorMsg
          show={errMsg['country']}
          msg="Please select the restaurant location"
        />
      </div>
      <Category {...formProps} chosenCat={chosenCat}/>
      <div className={style.dropdownInline}>
        <Dropdown
          id="inp-county"
          label="County"
          options={[
            { value: "default", label: "Please select business county" },
            ...counties.map((item) => ({ value: item, label: item })),
          ]}
          value={county}
          onChange={(e) => onChangeCounty(e.target.value) }
          disabled={country !== 'not_listed' ? true : false}
        />
        <div className={style.dropdownError}>
          <ErrorMsg
            show={errMsg['county']}
            msg="Please select a business county"
          />
        </div>
        {
          country === 'not_listed' ? (
            <div className={style.dropdownInline}>
              <Dropdown
                id="inp-city"
                label="City / Town / Village"
                options={[
                  { value: "default", label: "Please select business City / Town / Village" },
                  ...buildTownList(county).map((item) => ({ value: item, label: item })),
                ]}
                onChange={(e) => onChangeCity(e.target.value)}
              />
              <ErrorMsg
                show={errMsg['city']}
                msg="Please select a business city / town / village"
              />
            </div>
          ) : (
            <span>
              <label className={style.label}>
                City / Town / Village <sup className={style.required}>*</sup>
              </label>
              <input
                id="inp-city"
                className={style.inputField}
                value={city}
                onChange={(e) => onChangeCity(e.target.value)}
                disabled={(place !== null && emptyCity === true) ? false : true}
              />
              <ErrorMsg
                show={errMsg['city']}
                msg="Please select a business city / town / village"
              />
            </span>
          )
        }
      </div>
      <div className={`${style.title} ${style.titleMarginTop}`}>
        Your personal details
      </div>
      <div className={style.paragraph}>
        Your name and email address will not be published and are only required for verification purposes.
      </div>
      <div className={style.personalInfo}>
        <div className={style.labelLeft}>
          <label className={style.label}>
            First Name <sup className={style.required}>*</sup>
          </label>
          <input
            id="inp-firstName"
            className={style.inputField}
            value={firstName}
            onChange={(e) => onChangeFirstName(e.target.value)}
          />
          <span className={style.icon}>
            {firstName !== "" ? (
              specialCharacterFirstName ? (
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  className={style.wrongIcon}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className={style.checkIcon}
                />
              )
            ) : (
              <></>
            )}
          </span>
          <ErrorMsg
            show={errMsg['firstName']}
            msg="Please enter a valid first name"
          />
        </div>
        <div className={style.labelLeft}>
          <label className={style.label}>
            Last Name <sup className={style.required}>*</sup>
          </label>
          <input
            id="inp-lastName"
            className={style.inputField}
            value={lastName}
            onChange={(e) => onChangeLastName(e.target.value)}
          />
          <span className={style.icon}>
            {lastName !== "" ? (
              specialCharacterLastName ? (
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  className={style.wrongIcon}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className={style.checkIcon}
                />
              )
            ) : (
              <></>
            )}
          </span>
          <ErrorMsg
            show={errMsg['lastName']}
            msg="Please enter a valid surname"
          />
        </div>
      </div>
      <div className={style.labelLeft}>
        <label className={style.label}>
          Email <sup className={style.required}>*</sup>
        </label>
        <input
          id="inp-email"
          className={style.inputFieldEmail}
          type="email"
          value={email}
          onChange={(e) => onChangeEmail(e.target.value)}
        />
        <span className={style.iconEmail}>
          {email !== "" ? (
            specialCharacterEmail ? (
              <FontAwesomeIcon
                icon={faTimesCircle}
                className={style.wrongIcon}
              />
            ) : (
              <FontAwesomeIcon
                icon={faCheckCircle}
                className={style.checkIcon}
              />
            )
          ) : (
            <></>
          )}
        </span>
        <ErrorMsg
          show={errMsg['email']}
          msg="Please enter a valid email"
        />
      </div>
      <div className={style.ckeckboxes}>
        <label className={style.containerRadio}>
          <div className={style.overEighteen}>
            I am over 18 years of age <sup className={style.required}>*</sup>
          </div> 
          <input
            id="inp-overEighteen"
            type="checkbox"
            name="radio"
            onChange={onChangeOverEighteen}
          />
          <span className={style.checkmark}></span>
        </label>
        <ErrorMsg
          show={errMsg['overEighteen']}
          msg="Sorry, you must be over 18 to vote"
        />
      </div>     
      <div className={style.updateText}>
        Please sign me up to receive:
      </div>
      <div className={style.ckeckboxes}>
        <label className={`${style.containerRadio} ${style.smallText}`}>
          Marketing updates from the <strong>Restaurants Association of Ireland</strong>
          <input
            type="checkbox"
            name="radio"
            onChange={(e) => onChangeSubscription(e.target.value)}
            id="Restaurant Association of Ireland"
            value="RAI"
          />
          <span className={style.checkmark}></span>
        </label>
        <label className={`${style.containerRadio} ${style.smallText}`}>
          <strong> Features, events, exclusive offers and competitions </strong> from The Irish Times
          <input
            type="checkbox"
            name="radio"
            onChange={(e) => onChangeSubscription(e.target.value)}
            id="The Irish Times"
            value="IT"
          />
          <span className={style.checkmark}></span>
        </label>
        <label className={`${style.containerRadio} ${style.smallText}`}>
          The Irish Times <strong>Food & Drink Club newsletter </strong>
          <input
            type="checkbox"
            name="radio"
            onChange={(e) => onChangeSubscription(e.target.value)}
            id="Morning News Digest"
            value="MND"
          />
          <span className={style.checkmark}></span>
        </label>
      </div>

      <Recaptcha response={getResponse} />
      <button
        className={notARobot ? style.submitBtn : style.submitBtnDisable}
        onClick={() => submitBtn()}
      >
        Submit Nomination{" "}
        <FontAwesomeIcon icon={faArrowRight} className={style.arrowRight} />
      </button>
      <div className={style.footer}>
      </div>
    </div>
  );
};

const Category = memo(function Category({
  chosenCategory,
  onChangeCategory,
  chosenCat,
  errMsg,
  categoryName,
  onChangeCategoryName,
  specialCharacterCategoryName,
  descriptionValue,
  onChangeCategoryDescription,
  specialCharacterdescriptionValue,
}) {
  return (
    <>
      <div className={style.dropdownInline}>
        <Dropdown
          className={style.dropdownInput}
          id="inp-category"
          label="Category"
          options={categories}
          value={chosenCategory}
          onChange={onChangeCategory}
        />
        {chosenCat && chosenCat?.definition !== "" && (
          <div>
            <Card className={style.cardStyle}>
              <CardContent className={style.cardContetStyle}>
                {chosenCat?.definition}
              </CardContent>
            </Card>
          </div>
        )}
        <div className={style.dropdownError}>
          <ErrorMsg
            show={errMsg["category"]}
            msg="Please select a nomination category"
          />
        </div>
      </div>
      {chosenCat && chosenCat?.customFields && (
        <div className={style.extraField}>
          <div className={style.labelLeft}>
            <label className={style.label}>
              {chosenCat.customFields[0].label}
              <sup className={style.required}>*</sup>
            </label>
            <input
              id="inp-categoryName"
              placeholder="Please enter First Name and Last Name"
              className={style.inputField}
              value={categoryName}
              onChange={onChangeCategoryName}
            />
            <span className={style.icon}>
              {categoryName && (
                  <FontAwesomeIcon
                    icon={specialCharacterCategoryName ? faTimesCircle : faCheckCircle}
                    className={specialCharacterCategoryName ? style.wrongIcon : style.checkIcon}
                  />
              )
              }
            </span>
            <ErrorMsg
              show={
                errMsg["categoryName"] || errMsg["categoryNameValid"]
                  ? true
                  : false
              }
              msg={
                errMsg["categoryName"]
                  ? `Please add ${chosenCat.customFields[0].label}`
                  : `Please enter a valid ${chosenCat.customFields[0].label}`
              }
            />
          </div>
          {chosenCat?.customFields[1] && (
            <div className={style.labelLeft}>
              <label className={style.label}>
                {chosenCat.customFields[1].label}
                <sup className={style.required}>*</sup>
              </label>
              <textarea
                placeholder=""
                className={style.inputField}
                value={descriptionValue}
                onChange={onChangeCategoryDescription}
              ></textarea>
              <span className={style.icon}>
                {descriptionValue && (
                  <FontAwesomeIcon
                    icon={specialCharacterdescriptionValue ? faTimesCircle : faCheckCircle}
                    className={specialCharacterdescriptionValue ? style.wrongIcon : style.checkIcon}
                  />
                 )}
              </span>
              <ErrorMsg
                show={
                  errMsg["categoryDescription"] ||
                  errMsg["categoryDescriptionValid"]
                    ? true
                    : false
                }
                msg={
                  errMsg["categoryDescription"]
                    ? `Please add ${chosenCat.customFields[1].label}`
                    : `Please enter a valid ${chosenCat.customFields[1].label}`
                }
              />
            </div>
          )}
        </div>
      )}
    </>
  );
},
areCatPropsEqual);

function areCatPropsEqual(oldProps, newProps) {
  return (
    oldProps.chosenCategory === newProps.chosenCategory &&
    oldProps.categoryName === newProps.categoryName &&
    oldProps.descriptionValue === newProps.descriptionValue &&
    oldProps.errMsg["categoryDescription"] ===
      newProps.errMsg["categoryDescription"] &&
    oldProps.errMsg["categoryDescriptionValid"] ===
      newProps.errMsg["categoryDescriptionValid"] &&
    oldProps.errMsg["category"] === newProps.errMsg["category"] &&
    oldProps.errMsg["categoryName"] === newProps.errMsg["categoryName"] &&
    oldProps.errMsg["categoryNameValid"] ===
      newProps.errMsg["categoryNameValid"]
  );
}

export default Form;
