import axios from "axios";

export const fetchCounties = () => {
  return axios.get('/data/counties.json')
    .then(response => response.data);
};

export const fetchTowns = () => {
  return axios.get('https://rai-json.s3.eu-west-1.amazonaws.com/towns-places.json')
    .then(response => response.data);
};
