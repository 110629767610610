import React, { memo } from "react";
import style from "./ErrorMsg.module.scss";


const ErrorMsg = memo(function ErrorMsg({ show, msg }) {
    return <> {show && <div className={style.form_err_msg}>{msg}</div>}</>;
});

ErrorMsg.defaultProps = {
  show: false,
  msg: "Error",
};

export default ErrorMsg;
