import style from "./Dropdown.module.scss";
import { useState, useEffect } from "react";

const Dropdown = ({ label, value, options, onChange, disabled, id }) => {
  const [votedCategory, setVotedCategory] = useState([]);
  const getAllCacheData = async () => {
    setVotedCategory([]);
    var url = "irish-times";
    // List of all caches present in browser
    var names = await caches.keys();
    let userInfo = names.filter((name) => name === "UserInfo");
    userInfo.forEach(async (name) => {
      // Opening that particular cache
      const cacheStorage = await caches.open(name);
      // Fetching that particular cache data
      const cachedResponse = await cacheStorage.match(url);
      var data = await cachedResponse.json();
      if (data.votedCategories.length > 0)
        data.votedCategories
          // eslint-disable-next-line array-callback-return
          .map((elem) => {
            setVotedCategory((prev) => [...prev, elem]);
          });
    });
  };
  useEffect(() => {
    getAllCacheData();
  }, []);
  return (
    <label className={style.mainContainer}>
      <div className={style.label}>
        {label} <sup className={style.req}>*</sup>
      </div>

      <select
        id={id}
        placeholder="dbshvjs"
        value={value}
        onChange={onChange}
        className={style.dropdownSmall}
        // className={label === "Category" ? style.dropdown : style.dropdownSmall}
        disabled={disabled}
      >
        {options.map((option, key) => (
          <option
            value={option.value}
            key={key}
            className={style.optionTag}
            disabled={votedCategory.includes(option.value) ? true : false}
            hidden={option.value === "default" ? true : false}
          >
            {option.label}
          </option>
        ))}
      </select>
    </label>
  );
};
export default Dropdown;
