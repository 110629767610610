import React, { useState, useMemo, useEffect } from "react";
import throttle from "lodash/throttle";

import { useGoogleMaps } from "./GoogleMapsProvider";
import { useAutocomplete } from "@mui/base";

import powered_by_google from './powered_by_google_on_white.png';
import styles from './GoogleMapsSearch.module.scss';

const GoogleMapsPlaceSearch = ({ value, onChange, onChangeInput, onBlurInput, countryChosen }) => {
  const [options, setOptions] = useState([]);
  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    inputValue,
  } = useAutocomplete({
    id: 'place-search-autcomplete',
    options,
    value,
    autoComplete: true,
    freeSolo: true,
    onChange: (_e, value) => {
      let newValue;

      if (typeof value === "string") {
        newValue = { name: value };
      } else if (value === inputValue) {
        newValue = { name: inputValue };
      } else {
        newValue = value;
      }

      setOptions(newValue ? [newValue, ...options] : options);
      onChange(newValue);
    },
    filterOptions: (option) => option,
    getOptionLabel: (option) => typeof option === 'string' ? option : option?.name || option?.description,
    isOptionEqualToValue: (option, value) => option.name === value?.name,
  });
  
  const { getPredictions } = useGoogleMaps();

  const fetch = useMemo(
    () =>
      throttle((input, onSuccess, onError) => {
        getPredictions(input, countryChosen)
          .then((results) => onSuccess(results))
          .catch((error) => onError(error));
      }, 1000),
    [getPredictions, countryChosen]
  );

  useEffect(() => {
    let active = true;
    if (!inputValue || countryChosen === 'not_listed') {
      setOptions(value ? [value] : []);
      return;
    }

    fetch(
      inputValue,
      (results) => {
        if (!active) {
          return;
        }

        const options = results.map((item) => ({
          name: item.structured_formatting.main_text,
          description: item.structured_formatting.secondary_text,
          placeId: item.place_id,
        }));

        setOptions(options);
      },
      (error) => {
        if (!active) {
          return;
        }

        console.error(error);
        setOptions([]);
      }
    );

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch, countryChosen]);

  return (
    <div className={styles.Autocomplete}>
      <div {...getRootProps()}>
        <input
          className={styles.input}
          placeholder="Start typing to search"
          {...getInputProps()}
          onInput = {onChangeInput}
          onBlur = {onBlurInput}
        />
      </div>

      <img
        className={styles.Google}
        src={powered_by_google}
        alt="Powered by Google"
      />

      {groupedOptions.length > 0 ? (
        <ul {...getListboxProps()} className={styles.ListBox}>
          {groupedOptions.map((option, index) => (
            <li {...getOptionProps({ option, index })} key={option.placeId || option.name}>
              <span className={styles.Name}>{option.name}</span>
              <span className={styles.Description}>{option.description}</span>
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  );
};

export default GoogleMapsPlaceSearch;
