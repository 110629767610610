import React from 'react';

class HeightChangeDetector extends React.Component {
    render() { return; }
    componentDidMount() {
        const resizeObserver = new ResizeObserver(entries => 
            window.parent.postMessage(entries[0].target.scrollHeight, '*')
          )
          resizeObserver.observe(document.getElementById("root"))
    }
}

export default HeightChangeDetector;