import { createContext, useContext, useEffect, useState } from "react";

import { fetchCounties, fetchTowns } from './services/data';

const DataContext = createContext(null);

const useData = () => useContext(DataContext);

const DataProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [counties, setCounties] = useState([]);
  const [towns, setTowns] = useState([]);

  useEffect(() => {
    fetchCounties()
      .then(counties => setCounties(counties))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    fetchTowns()
      .then(towns => setTowns(towns))
      .finally(() => setLoading(false));
  }, []);

  const value = {
    counties, towns
  };

  return (
    <DataContext.Provider value={value}>
      {!loading ? children : null}
    </DataContext.Provider>
  )
};

export { useData, DataProvider };