import "./App.css";
import Router from "./router";
import { GoogleMapsProvider } from "./components/GoogleMaps/GoogleMapsProvider";
import React from "react";

function App() {
  return (
    <div className="App">
        <GoogleMapsProvider apiKey={process.env.REACT_APP_GOOGLE_API_KEY}>
          <Router />
        </GoogleMapsProvider>
    </div>
  );
}

export default App;
