import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Voting from "../views/VotingPage/Voting";
import VotingConfirm from "../views/ConfirmVotingPage/VotingConfirm";
import { DataProvider } from "../DataProvider";
import loadable from "@loadable/component";

const LoginPage = loadable(() => import(/* webpackChunkName: "loginpage" */ '../views/LoginPage/LoginPage'));
const RequireAuth = loadable(() => import(/* webpackChunkName: "requireauth" */ '../utils/requireAuth'));

const voting =
<>
<Route exact path="/" element={<DataProvider><Voting /></DataProvider>}></Route>
<Route exact path="/confirmation" element={<VotingConfirm />}></Route>
</>

const closed = 
<>
<Route exact path="/" element={<div>Nominations are now closed.</div>}></Route>
</>

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        {process.env.REACT_APP_DISABLE_FORM ? closed : voting}
        <Route exact path="/login" element={<LoginPage />}></Route>
        <Route exact path="/dashboard" element={<RequireAuth />}></Route>
      </Routes>
    </BrowserRouter>
  );
};
export default Router;
