import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { validateRecaptcha } from "./validateRecaptcha";

const Recaptcha = (props) => {
  const API_KEY = process.env.REACT_APP_RECAPTCHA_API_KEY;
  const recaptchaRef = React.createRef();
  function onChange(value) {
    let data = {
      response: value,
      secret: process.env.REACT_APP_RECAPTCHA_API_SECRET_KEY,
    };
    validateRecaptcha(data).then((response) => {
      props.response(response.data.success);
    });
  }

  const onExpired = () => {
    let id = document.getElementById("RecaptchaField1");
    recaptchaRef.current.props.grecaptcha.render(id, {
      sitekey: process.env.REACT_APP_RECAPTCHA_API_KEY,
    });
  };

  return (
    <div id="RecaptchaField1">
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={API_KEY}
        onChange={onChange}
        onExpired={onExpired}
      />
    </div>
  );
};

export default Recaptcha;
