import style from "./VotingConfirm.module.scss";
import spacing from "../../assets/scss/_spacing.module.scss";
import { VscArrowLeft } from "react-icons/vsc";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faThumbsUp,
  faQuoteLeft,
  faQuoteRight,
} from "@fortawesome/free-solid-svg-icons";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton
} from "react-share";
import { FacebookIcon, XIcon, WhatsappIcon, LinkedinIcon } from "react-share";
import categories from "../../utils/categories";

const VotingConfirm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [votedCategory, setVotedCategory] = useState([]);
  const [filteredCats, setFilteredCats] = useState([]);
  
  const onGoToNominationPage = (value) => {
    window.parent.postMessage({scrollToTop: true}, '*');
    navigate("/", {
      state: {
        selectedCategory: value,
      },
    });
  };

  const onGoBackToNominationPage = () => {
    window.parent.postMessage({scrollToTop: true}, '*');
    navigate("/");
  };

  const getAllCacheData = async () => {
    var url = "irish-times";
    // List of all caches present in browser
    var names = await caches.keys();
    let userInfo = names.filter((name) => name === "UserInfo");
    userInfo.forEach(async (name) => {
      // Opening that particular cache
      const cacheStorage = await caches.open(name);
      // Fetching that particular cache data
      const cachedResponse = await cacheStorage.match(url);
      var data = await cachedResponse.json();
      if (data.votedCategories.length > 0)
        setVotedCategory(data.votedCategories);
    });
  };

  useEffect(() => {
    if (!location.state) {
      navigate("/");
    }
  }, [location, navigate]);

  useEffect(() => {
    getAllCacheData();
  }, []);

  useEffect(() => {
    if (!votedCategory) return null;
    const results = categories.filter((object1) => {
      return !votedCategory.some((object2) => {
        return !object1.categoryId || object1.value === object2;
      });
    });
    setFilteredCats(results);
  },[votedCategory]);

  return (
    <div className={style.test}>
      {location.state ? (
        <div className={style.container}>
          {/* <div className={style.firstElement}>
            <RightMenu />
          </div> */}
          <div className={style.firstElement}>
            {/* <div className={`${style["text"]} ${spacing["mt-51"]}`}>
              Hi {location.state.firstName} {location.state.lastName},
            </div>
            <div className={style.text}>
              thank you for submitting your nomination for
            </div> */}
            <div className={style.thankYouContent}>
            	<div className={`${style["restaurantName"]} ${spacing["mt-51"]}`}>
              {/* {location.state.restaurant} */}
              Thanks for your nomination!
            	</div>
            	<div className={`${style["categoryCounty"]} ${spacing["mt-10"]}`}>
              {/* Category:
              <span className={`${style["nomination"]}`}>
                {location.state.category}
              </span> */}
              Share your nomination and help them to win!
            	</div>
            	<div className={`${style["nominationText"]} ${spacing["mt-10"]}`}>
              {/* County:{" "}
              <span className={`${style["nomination"]}`}>
                {location.state.county}
              </span> */}
              <FontAwesomeIcon icon={faQuoteLeft} className={style.quoteLeft} />{" "}
              I have just nominated <span> {location.state.restaurant} </span>for{" "}
              {location.state.category} in the <span>Irish Restaurant Awards 2024 </span>
              @restawards #FoodOscars{" "}
              <FontAwesomeIcon
                icon={faQuoteRight}
                className={style.quoteRight}
              />
            	</div>
            	<div className={style.shareButtons}>
              <br />
              <LinkedinShareButton
                title={`I have just nominated ${location.state.restaurant} for ${location.state.category} in the Irish Restaurant Awards 2024`}
                url={process.env.REACT_APP_VOTING_PAGE_URL}
              >
                <LinkedinIcon size={32} className={style.twitter} />
              </LinkedinShareButton>
              <TwitterShareButton
                title={`I have just nominated ${location.state.restaurant} for ${location.state.category} in the Irish Restaurant Awards 2024`}
                url={process.env.REACT_APP_VOTING_PAGE_URL}
                hashtags={["restawards", "FoodOscars"]}
              >
                <XIcon size={32} className={style.twitter} />
              </TwitterShareButton>
              <WhatsappShareButton
                title={`I have just nominated ${location.state.restaurant} for ${location.state.category} in the Irish Restaurant Awards 2024`}
                url={process.env.REACT_APP_VOTING_PAGE_URL}
                hashtags={["restawards", "FoodOscars"]}
              >
                <WhatsappIcon size={32} className={style.twitter} />
              </WhatsappShareButton>
              <FacebookShareButton
                url={process.env.REACT_APP_VOTING_PAGE_URL}
                hashtag={["#restawards"]}
              >
                <FacebookIcon size={32} className={style.twitter} />
              </FacebookShareButton>
            	</div>
            	{/* <div className={`${style["text"]} ${spacing["mt-51"]}`}>
              		You will recieve an email of your nomination as a reciept, however
              		if you change your mind you will be able to submit a new
              		nomination
            	</div> */}
			</div>

			<div className={`${style["text"]} ${spacing["mt-38"]}`}>
      You can only nominate once per category, but why not <strong>show your support</strong> and nominate the same (or another) establishment in a different category from the list below?
            	</div>

            {/* <div className={`${style["text"]} ${spacing["mt-51"]}`}>
              Regards
            </div>
            <div className={`${style["text"]} ${style["textPurple"]}`}>
              Irish Restaurants Awards Team
            </div> */}
            <div className={style.listOfCategories}>
              {filteredCats.map((elem, key) => (
                <div
                  key={key}
                  className={style.list}
                  onClick={() => {
                    onGoToNominationPage(elem.value);
                  }}
                >
                  <FontAwesomeIcon
                    icon={faThumbsUp}
                    className={style.checkIcon}
                  />
                  {elem.label}
                </div>
              ))}
            </div>
            <div
              className={`${style["backButton"]}`}
              onClick={onGoBackToNominationPage}
            >
              <VscArrowLeft
                style={{
                  marginRight: "10px",
                  paddingBottom: "3px",
                }}
                size="20"
              ></VscArrowLeft>
              Back to nomination page
            </div>
          </div>
          <div className={style.footer}>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default VotingConfirm;
